'use client'

import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HttpError, useList } from '@refinedev/core';
import Loading from '../../components/loading/loading';
import { Datepicker, MbscDatepickerChangeEvent } from '@mobiscroll/react';
import ShiftCard from './shift-card';
import ShiftManagementHeader from './shift-management-header';
import ShiftDrawer from './shift-drawer';
import { Alert } from 'flowbite-react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import VHTToast from '../../components/toast';

export default function CalendarDayView() {
  const { date: dateParam } = useParams();
  const [openShiftDrawer, setOpenShiftDrawer] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string | undefined>(dateParam);

  const { data: shiftsData, isLoading, refetch } = useList<Shift, HttpError>({
    resource: 'shifts/findByDate?date=' + (dateParam),
    pagination: {
      mode: 'off'
    },
  });

  const container = useRef(null)
  const navigator = useNavigate();
  const handleDateChange = (date: string) => {
    setSelectedDay(date);
    date && navigator(`/shift-management/day/${date}`);
  }

  return isLoading ? <Loading /> : (
    <div className="flex h-full flex-col">
        <ShiftManagementHeader setOpenShiftDrawer={setOpenShiftDrawer} />
      <div className="isolate flex h-full flex-auto overflow-hidden bg-white px-2">
        <div ref={container} className="flex flex-auto flex-col overflow-auto">
            <div className="m-4 grid flex-auto grid-cols-1 grid-rows-1">
                {
                  shiftsData?.data?.length ?
                  shiftsData?.data?.map(s => {
                    return <ShiftCard key={s?.shift_id} shift={s} toastId={s.shift_id} refetchData={refetch} showToast={showToast} setShowToast={setShowToast} />
                  }) : <div>
                      <Alert icon={ExclamationTriangleIcon} color="yellow" className="m-4">No shifts present for this date.</Alert>
                    </div>
                }
          </div>
        </div>
        {selectedDay && <Datepicker
          display="inline"
          returnFormat={"iso8601"}
          themeVariant={"light"}
          value={selectedDay}
          name="date"
          controls={['calendar']}
          className="shadow-none"
          onChange={(args: MbscDatepickerChangeEvent) => handleDateChange(args.value as string)}
        />}
      </div>
      <ShiftDrawer open={openShiftDrawer} setOpen={setOpenShiftDrawer} refetchData={refetch}/>
      <VHTToast />
    </div>
  )
}
