import { MbscCalendarEvent } from '@mobiscroll/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatTime = (time: string): string => {
  return dayjs.utc(time).format('hh:mm A');
};

const setColor = (opening: Opening, applications: Application[]) => {
const { status } =  opening;
  if (status === 'CANCELLED') {
    return 'red';
  } else if (opening.assigned_clinician && status === 'CONFIRMED') {
    return 'blue';
  } else if (opening.status === 'COMPLETED' || opening.status === 'CONFIRMED') {
    return 'green';
  } else if (applications.length > 0 && status === 'ACTIVE') {
    return 'yellow';
  } else {
    return 'gray';
  }
};

const generateEvents = (shifts: Shift[]): MbscCalendarEvent[] => {
  return shifts.flatMap((shift) => shift.openings.map<MbscCalendarEvent>((opening) => {
    const parsedStart = formatTime(opening.start_time);
    const parsedEnd = formatTime(opening.end_time);

    return {
      id: opening.opening_id,
      start: opening.start_time,
      title: `${shift.positions.position_name}: ${parsedStart} - ${parsedEnd}`,
      color: setColor(opening, shift.applications),
      customData: shift,
    };
  }));
};

export default generateEvents;