import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

export const Login: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    console.log('hey use effect')
    const handleLogin = async () => {
      console.log('isAuthenticated', isAuthenticated)
      if (!isAuthenticated) {
        console.log('will we login')
        await loginWithRedirect();
      }
    };
    handleLogin();
  }, [isAuthenticated, loginWithRedirect]);

  return <></>;
};