import React from 'react';
import { Label, Select } from 'flowbite-react';

type BreakTimeDropdownProps = {
  register: any;
};

const BreakTimeDropdown = ({ register }: BreakTimeDropdownProps) => {
  return <div>
      <Label>Break Time</Label>
      <Select {...register('break_time', { required: true, valueAsNumber: true })} id="break" required name="break_time" >
        <option key={0} value={0}>
          {0}
        </option>
        <option key={15} value={15}>
          {15}
        </option>
        <option key={30} value={30}>
          {30}
        </option>
        <option key={45} value={45}>
          {45}
        </option>
        <option key={60} value={60}>
          {60}
        </option>
      </Select>
    </div>
};

export default BreakTimeDropdown;