import React, { useState } from 'react';

interface TimepickerProps {
  register: any;
}

const Timepicker = ({
  register
}: TimepickerProps) => {
  const [time, setTime] = useState({
    startTime: '12:00',
    endTime: '20:00',
  });
  return (
    <div className="flex flex-row gap-2">
      <div className="mr-auto flex w-full flex-col">
        <label htmlFor="start-time" className="dark:text-white mb-2 block text-sm font-medium text-gray-900">Start
          time:</label>
        <div className="relative w-full">
          <input {...register('start_time', {required: true})} type="time" id="start-time"
           className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500"
           value={time.startTime} required onChange={(event) => {
             setTime({ ...time, startTime: event.target.value });
             console.log(event.target.value);
           }} />
        </div>
      </div>
      <div className="mr-auto flex w-full flex-col">
        <label htmlFor="end-time" className="dark:text-white mb-2 block text-sm font-medium text-gray-900">End
          time:</label>
        <div className="relative">
          <input type="time" id="end-time" {...register('end_time', {required: true})}
                 className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500" value={time.endTime} onChange={(event) => {
              setTime({ ...time, endTime: event.target.value });
            }}  required />
        </div>
      </div>
    </div>);
}

export default Timepicker;