import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Spinner } from 'flowbite-react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import {
  CheckIcon,
  ClockIcon, ExclamationTriangleIcon,
  MapPinIcon,
  NoSymbolIcon,
  PencilIcon,
} from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import ShiftDetails from './shift-details';
import { HttpError, useUpdate } from '@refinedev/core';
import ShiftBadge from '../../components/shift-fill-badge';
import { toast } from 'react-toastify';


type ShiftCardProps = {
  shift: Shift;
  refetchData: () => Promise<any>;
  showToast: boolean;
  setShowToast: (showToast: boolean) => void;
  toastId: string;
}

const ShiftCard = ({shift, refetchData, setShowToast, toastId, showToast }: ShiftCardProps) => {
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(true);
  const isCancelled = shift.openings.filter(o => o.status === 'CANCELLED').length === shift.openings.length;

  const { mutate: cancelShift, isLoading, isError, isSuccess } = useUpdate<Shift, HttpError>({
    resource: 'shifts/cancel',
    id: shift?.shift_id,
    mutationOptions: {
      onSuccess: async () => {
        await refetchData();
        setShowToast(true);
      },
    }
  });
  useEffect(() => {
    if (showToast && isLoading) {
      toast('Cancelling shift...',{
        type: 'info',
        toastId: toastId,
        icon: <Spinner size='md' />,
      });
    } else if (showToast && isSuccess) {
      toast.update(toastId, {
        type: 'success',
        render: 'Shift cancelled successfully',
        icon: <CheckIcon width="20px" />,
        autoClose: 5000,
      })
    } else if (showToast && isError) {
      toast.update(toastId, {
        type: 'error',
        render: 'There was an error cancelling shift',
        icon: <ExclamationTriangleIcon width="20px" />,
        autoClose: 5000,
      })
    }
  }, [isError, isLoading, isSuccess, showToast, toastId]);


  return (
    <>
      <Card className="mx-auto mb-4 w-full max-w-4xl shadow">
        { isCancelled && <Alert className='mt-2' color="red"> This shift has been cancelled, no actions may be taken on it. </Alert> }
        <div className='flex items-center justify-between'>
          <h3 className="flex text-3xl font-medium">
            {shift?.positions?.position_name}
          </h3>
          <ShiftBadge openings={shift.openings} />
        </div>
        <div className='flex flex-row gap-4'>
          <div className="flex items-center space-x-2 font-medium text-gray-500">
            <CalendarIcon width="20px" />
            <h5>{dayjs(shift?.date ?? '').utc().format('dddd - MMMM DD, YYYY')}</h5>
          </div>
          <div className="flex items-center space-x-2 font-medium text-gray-500">
            <ClockIcon width="20px" />
            <h5>{dayjs(shift?.openings[0]?.start_time).utc().format('hh:mm A')} - {dayjs(shift?.openings[0]?.end_time).utc().format('hh:mm A')}</h5>
          </div>
          <div className="flex items-center space-x-2 font-medium text-gray-500">
            <MapPinIcon width="20px" />
            <h5>{shift?.facility?.facility_name}</h5>
          </div>
        </div>
        <ShiftDetails shift={shift} readOnlyMode={readOnlyMode} setReadOnlyMode={setReadOnlyMode} refetch={refetchData} />
        { readOnlyMode && !isCancelled && (<div className="flex flex-row justify-end space-x-2">
          <Button
            color="red"
            onClick={() => {
              setShowToast(true);
              cancelShift({values: {}})}
            }>
              <NoSymbolIcon className="mr-2" width="15px" />
              Cancel Shift
          </Button>
          <Button
            color="blue"
            onClick={() => setReadOnlyMode(!readOnlyMode)}>
            <PencilIcon className="mr-2" width="15px" />
            Edit
          </Button>
        </div>)}
      </Card>
    </>
  )
}

export default ShiftCard;