import React from "react";
import Card from "../../components/card";
import {DescriptionDetails, DescriptionList, DescriptionTerm} from "../../components/description-list";
import {Badge} from "../../components/badge";

const BillingSummary: React.FC = () => {
  return (<Card
    title="Billing Summary"
  >
    <DescriptionList className='mb-2'>
      <DescriptionTerm className='flex flex-row items-center'>
        <div className='flex-none rounded-full p-1 text-gray-500 bg-gray-100/10 mr-2'>
          <div className="h-2 w-2 rounded-full bg-current"/>
        </div>
        CREDIT LIMIT</DescriptionTerm>
      <DescriptionDetails className='ml-auto'>$10,000</DescriptionDetails>

      <DescriptionTerm className='flex flex-row items-center'>
        <div className='flex-none rounded-full p-1 text-yellow-500 bg-gray-100/10 mr-2'>
          <div className="h-2 w-2 rounded-full bg-current"/>
        </div>
        CURRENT MONTH</DescriptionTerm>
      <DescriptionDetails className='flex flex-row items-center'>
        <Badge className="mx-auto" color='yellow'>DUE: 10/15/2024</Badge>
        $1,150
      </DescriptionDetails>

      <DescriptionTerm className='flex flex-row items-center'>
        <div className='flex-none rounded-full p-1 text-green-500 bg-gray-100/10 mr-2'>
          <div className="h-2 w-2 rounded-full bg-current"/>
        </div>
        AUGUST INVOICE</DescriptionTerm>
      <DescriptionDetails className='flex flex-row items-center'>
        <Badge className="mx-auto" color='green'>PAID</Badge>
        $2,500
      </DescriptionDetails>

      <DescriptionTerm className='flex flex-row items-center'>
        <div className='flex-none rounded-full p-1 text-red-500 bg-gray-100/10 mr-2'>
          <div className="h-2 w-2 rounded-full bg-current"/>
        </div>
        PAST DUE</DescriptionTerm>
      <DescriptionDetails className='flex flex-row items-center'>
        <Badge className="mx-auto" color='red'>PAST DUE</Badge>
        $1,300
      </DescriptionDetails>

      <DescriptionTerm className='flex flex-row items-center'>
        <div className='flex-none rounded-full p-1 text-blue-500 bg-gray-100/10 mr-2'>
          <div className="h-2 w-2 rounded-full bg-current"/>
        </div>
        AVAILABLE CREDIT
      </DescriptionTerm>
      <DescriptionDetails className='ml-auto'>$7,550</DescriptionDetails>
    </DescriptionList>
    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
      <div className="text-sm">
        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
          View Invoices
        </a>
      </div>
    </div>
  </Card>)
}

export default BillingSummary;