import React, { PropsWithChildren } from "react";
import {Heading, Subheading} from "./heading";
import {Divider} from "./divider";
type CardProps = {
  title: string;
  subtitle?: string;
  className?: string;
  noDivider?: boolean;
}

const Card: React.FC<CardProps & PropsWithChildren> = ({ title, subtitle, className, noDivider = false, children }) => {
  return <div className={`relative overflow-hidden rounded-lg px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 ${className}`}>
    <Heading className="text-gray-900" level={3}>{title}</Heading>
    {!noDivider && <Divider className="my-2" />}
    <Subheading level={6}>{subtitle}</Subheading>
    <div className="mt-2 w-100 text-sm text-gray-500">
      {children}
    </div>
  </div>
}

export default Card;