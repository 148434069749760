import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { Flowbite } from 'flowbite-react';

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="auth.vitalhivetech.com"
      audience="https://dev-ojc5qviazhkyyskj.us.auth0.com/api/v2/"
      clientId="tnG3JwAamSFiSXXEHXm7ytVfJQVADPd8"
      redirectUri={`${window.location.origin}/dashboard`}
    >
      <Flowbite>
        <App />
      </Flowbite>
    </Auth0Provider>
  </React.StrictMode>
);
