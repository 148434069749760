import { Avatar } from "./avatar";
import ReliabilityRank from "./reliability-rank";
import React from "react";
import {Badge} from "./badge";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "./table";
type Shift = {
    id: number;
    imageUrl: string;
    name: string;
    reliability: number;
    datetime: string;
    date: string;
    time: string;
    status: string;
    color: string;
    clockInTime?: string;
    clockOutTime?: string;
}
type Props = {
  shifts:Shift[];
};

// TODO: Make sure you fix the overflow on mobile!
const ClinicianCondensedProfileRows: React.FC<Props> = ({ shifts }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (<div className="w-100 mt-4">
    <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Scheduled Time</TableHeader>
            <TableHeader>Clock In Time</TableHeader>
            <TableHeader>Clock Out Time</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {shifts.map((s) => (
            <TableRow key={s.id}>
              <TableCell>
                <div className="flex items-center gap-4">
                  <Avatar src={s.imageUrl} square={true} className="size-12" />
                  <div>
                    <div className="font-medium">{s.name}</div>
                    <div className="text-zinc-500 mt-1">
                      <a href="#" className="hover:text-zinc-700">
                        <ReliabilityRank reliability={s.reliability}/>
                      </a>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                { /* @ts-ignore **/ }
                {s.status && <Badge color={s.color}>{s.status}</Badge>}
              </TableCell>
              <TableCell>
                {s.time}
              </TableCell>
              <TableCell>
                {s.clockInTime}
              </TableCell>
              <TableCell>
                {s.clockOutTime}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  </div>);
}

export default ClinicianCondensedProfileRows;