import React from "react";
import Card from "../../components/card";
import ClinicianCondensedProfileRows from "../../components/clinician-condensed-profile-rows";

const scheduledClinicians = [
  {
    id: 3,
    date: 'August 12th, 2024',
    time: '2:00 PM - 12:00 AM',
    datetime: '2024-12-08T140:00',
    endDateTime: '2024-13-08T00:00',
    position: 'STNA',
    status: 'LATE',
    color: 'red',
    name: 'Jason Meyers',
    reliability: 3.5,
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    openingId: 1,
  },
  {
    id: 1,
    date: 'August 12th, 2024',
    time: '12:00 PM - 10:00 PM',
    datetime: '2024-12-08T12:00',
    endDateTime: '2024-12-08T22:00',
    position: 'RN',
    name: 'Leslie Alexander',
    status: 'IN PROGRESS',
    color: 'yellow',
    reliability: 4.5,
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    openingId: 1,
    clockInTime: '11:58 AM',
  },
  {
    id: 2,
    date: 'August 12th, 2024',
    time: '12:00 PM - 10:00 PM',
    datetime: '2024-12-08T12:00',
    endDateTime: '2024-12-08T22:00',
    position: 'RN',
    status: 'UPCOMING',
    color: 'blue',
    name: 'Lindsay Walton',
    reliability: 4.8,
    imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    openingId: 1,
  },
  {
    id: 4,
    date: 'August 12th, 2024',
    time: '12:00 PM - 10:00 PM',
    datetime: '2024-12-08T12:00',
    endDateTime: '2024-12-08T22:00',
    position: 'RN',
    status: 'COMPLETED',
    color: 'green',
    name: 'Miley Timms',
    reliability: 4.3,
    imageUrl: 'https://i.pravatar.cc/45',
    openingId: 1,
    clockInTime: '12:03 PM',
    clockOutTime: '10:05 PM',
  },
]
const TodaysSchedule: React.FC = () => {
  return (
    <Card title="Today's Schedule" noDivider={true}>
      <ClinicianCondensedProfileRows shifts={scheduledClinicians}/>
    </Card>
  )
}

export default TodaysSchedule;