import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, ButtonGroup } from 'flowbite-react';

type ShiftManagementHeaderProps = {
  setOpenShiftDrawer: (toggle: boolean) => void;
}
const ShiftManagementHeader = ({ setOpenShiftDrawer }: ShiftManagementHeaderProps) => {
  const navigate = useNavigate();

  const handleDayButtonClick = () => {
    const today = dayjs().format('YYYY-MM-DD');
    navigate(`/shift-management/day/${today}`);
  };

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-200 px-2 py-4">
      <ButtonGroup>
        <Button
          color={location.pathname === '/shift-management' ? 'blue' : 'gray'}
          outline={location.pathname.startsWith('/shift-management/day')}
          onClick={() => navigate('/shift-management')}
        >
          Month
        </Button>
        <Button
          color={location.pathname.startsWith('/shift-management/day') ? 'blue' : 'gray'}
          outline={!location.pathname.startsWith('/shift-management/day')}
          onClick={handleDayButtonClick}
        >
          Day
        </Button>
      </ButtonGroup>
      <Button className="ml-auto" color="blue" onClick={() => setOpenShiftDrawer(true)}>
        Post Shift
      </Button>
    </header>
  )
}

export default ShiftManagementHeader;