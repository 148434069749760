import { Accordion, Alert, Button, Label, Textarea } from 'flowbite-react';
import { Text } from '../../components/text';
import { InformationCircleIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ClinicianProfileRow from '../../components/clinician-profile-row';
import { HttpError } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Nullable } from '../../shared/types/misc';
import ApplicationRow from './application-row';
import BreakTimeDropdown from '../../components/forms/break-select';

type ShiftDetailsProps = {
  shift: Shift | undefined;
  setReadOnlyMode: Dispatch<SetStateAction<boolean>>;
  readOnlyMode: boolean;
  refetch: () => Promise<any>;
};

const ShiftDetails = ({ shift, readOnlyMode, setReadOnlyMode, refetch }: ShiftDetailsProps) => {
  const {
    refineCore: { onFinish, setId},
    register,
    handleSubmit,
    reset,
  } = useForm<UpdateShift, HttpError, Nullable<UpdateShift>>({
    refineCoreProps: {
      resource: "shifts",
      action: "edit",
      id: shift?.shift_id,
      onMutationSuccess: () => {
        setReadOnlyMode(true);
        refetch().catch(() => {console.log('Failed to refetch')});
        // query && query.refetch();
      },
    },
  });

  useEffect(() => {
    setId(shift?.shift_id);
  }, []);

  const scheduledClinicians = shift?.openings?.filter(o => o.assigned_clinician) ?? [];
  const pendingApplications = shift?.applications?.filter(a => a.status === 'PENDING') ?? [];
  console.log(shift)
  const rejectedApplications = shift?.applications?.filter(a => a.status === 'REJECTED') ?? [];

  return readOnlyMode && shift ? (
    <>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <Label>Description</Label>
          <Text>{shift?.description}</Text>
        </div>
        <div>
          <Label>Instructions</Label>
          <Text>{shift?.instructions}</Text>
        </div>
        <div>
          <Label>Break Time</Label>
          <Text>{shift?.break_time}</Text>
        </div>
        <div>
          <Label>Number of Openings</Label>
          <Text>{shift?.openings.length}</Text>
        </div>
      </div>
      <div className="flex grow">
        <Accordion className="grow">
          <Accordion.Panel>
            <Accordion.Title>Confirmed Clinicians</Accordion.Title>
            <Accordion.Content>
              {shift && scheduledClinicians.filter(o => o.assigned_clinician).map((ac) => {
                return ac.clinicians && (
                  <ClinicianProfileRow shift={shift} clinician={ac.clinicians} status={shift.openings.find(o => {
                    return o.assigned_clinician === ac.assigned_clinician;
                  })?.status ?? 'UNKNOWN'} key={ac.assigned_clinician} />
                );
              })}
              {scheduledClinicians.length === 0 &&
                <div>
                  <Text>There are no clinicians currently confirmed for openings on this shift.</Text>
                  {pendingApplications.length > 0 &&
                    <Alert className='mt-2' color="blue" icon={InformationCircleIcon}>
                      You have {pendingApplications.length} pending applications. Go select a clinician!
                    </Alert>
                  }
                </div>
              }
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>Applications</Accordion.Title>
            <Accordion.Content>
              <div>
                <h2 className='mb-2 font-medium'>Pending</h2>
                {pendingApplications.length ?
                  pendingApplications.map((a) => {
                    return a.clinician_id && <div key={a.application_id}><ApplicationRow shift={shift} application={a} /></div>
                  }) : <Text>You have no pending applications at this time.</Text>
                }
              </div>
              <div className='mt-4'>
                <h2 className='mb-2'>Rejected</h2>
                {rejectedApplications.length ?
                  rejectedApplications.map((a) => {
                    return a.clinician_id &&
                      <div key={a.application_id}>
                        <ApplicationRow shift={shift} application={a} />
                      </div>
                  }) : <Text>You have no rejected applications at this time.</Text>
                }
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>
    </>
  ) : (
    <form onSubmit={handleSubmit(onFinish)}>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <Label>Description</Label>
          <Textarea
            required
            placeholder="Description"
            {...register('description', { required: true })}
            defaultValue={shift?.description}
          />
        </div>
        <div>
          <Label>Instructions</Label>
          <Textarea
            required
            placeholder="Instructions"
            {...register('instructions', { required: true })}
            defaultValue={shift?.instructions}
          />
        </div>
        { shift && <BreakTimeDropdown register={register} /> }
      </div>
      <div className='mt-4 flex items-center justify-end gap-2'>
        <Button outline onClick={() => {
          reset();
          setReadOnlyMode(true);
        }} color='gray'>
          <NoSymbolIcon />
          Cancel
        </Button>
        <Button type='submit' color='blue'>
          <NoSymbolIcon />
          Save
        </Button>
      </div>
    </form>
  );
};

export default ShiftDetails;