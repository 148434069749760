import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import {
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon
} from "@heroicons/react/24/solid";
import BillingSummary from "./billing-summary";
import TodaysSchedule from "./todays-schedule";
import classNames from "../../shared/utils/class-names";

const stats = [
  { id: 1, name: 'Shifts Completed', stat: '27', icon: CheckBadgeIcon, change: '10', changeType: 'increase', subtext: 'from 17 this time last month', color: 'bg-green-500' },
  { id: 2, name: 'Shifts Confirmed', stat: '5', icon: ClipboardDocumentCheckIcon, color: 'bg-blue-500' },
  { id: 3, name: 'Shifts Open', stat: '7', icon: ExclamationTriangleIcon, color: 'bg-yellow-500' },
  { id: 4, name: 'Shift Cancellations', stat: '5', icon: NoSymbolIcon, change: '2', changeType: 'decrease', subtext: 'from 3 this time last month', color: 'bg-red-500' },
]
export default function Dashboard() {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className={`absolute rounded-md ${item.color} p-3`}>
                <item.icon aria-hidden="true" className="size-6 text-white"/>
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold',
                )}
              >
                {item?.changeType && (item.changeType === 'increase' ? (
                  <ArrowUpIcon aria-hidden="true" className="size-5 shrink-0 self-center text-green-500"/>
                ) : (
                  <ArrowDownIcon aria-hidden="true" className="size-5 shrink-0 self-center text-red-500"/>
                ))}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </p>
              <div className="ml-2 text-sm text-gray-900">{item.subtext}</div>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 p-4 sm:px-6">
                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
      <dl className="mt-5 grid grid-cols-1">
        <TodaysSchedule />
      </dl>
      <dl className="mt-5 grid gap-5 sm:grid-cols-1 md:grid-cols-2">
        <BillingSummary />
      </dl>
    </div>
)
}
