// src/components/ShiftBadge.tsx
import React from 'react';
import { Badge } from 'flowbite-react';

type ShiftBadgeProps = {
  openings: Opening[];
};

const ShiftBadge = ({ openings }: ShiftBadgeProps) => {
  const confirmed = openings.filter(o => o.assigned_clinician).length;
  const open = openings.filter(o => !o.assigned_clinician).length;
  const totalOpenings = openings.length;
  const isCancelled = openings.filter(o => o.status === 'CANCELLED').length === openings.length;


  return isCancelled ? (
    <Badge className="h-8 px-4" color="red">CANCELLED</Badge>
  ) : (
    <Badge className="h-8 px-4" color={totalOpenings === confirmed ? 'green' : 'blue'}>
      <span>OPENINGS: {totalOpenings}</span>
      <span className='mx-2'>|</span>
      <span>UNFILLED: {open}</span>
      <span className='mx-2'>|</span>
      <span>CONFIRMED: {confirmed}</span>
    </Badge>
  );
};

export default ShiftBadge;