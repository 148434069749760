import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loading: React.FC = () => {
  return (
    <div style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}>
      <img src={`/logo-dark-mono.png`} alt="VitalHive Logo" style={{ height: "200px" }} />
      <ThreeDots
        visible={true}
        height="60"
        width="60"
        color="#fbb03b"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default Loading;
